<template>  
    <!--代码部分begin-->
    <div>
        <iframe :src="url" frameborder="0" ></iframe>
    </div>
</template>

<style>
iframe{
    width:100%;
    height:956px;
}
</style>

<script>

export default {
  name: 'ThreeProductdetail',
  data () {
      return {
        list: [],
        id: this.$route.params.id,
        url: 'static/index.html?id='+this.$route.params.id+"&token="+localStorage.token,
      }
  },
   mounted () {
    this.getList()
  },
   methods: {
    getList () {
      this.$common.fetchList('/user/product/threedimage/lists/'+this.id, {}).then(data => {
        if (data.code === 200) {
         const result = data.data
         this.list = result
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    },
   }
}

</script>
